body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--app-bg);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --content-width: 700px;
  --menu-width: 100px;
  --menu-padding: 1rem;
  --menu-border: 2px;
  --total-menu-width: calc(var(--menu-border) + var(--menu-width) + var(--menu-padding) + var(--menu-padding));
  --app-width: 802px;
  --menu-button-border: 5px;
  --accent-text: bisque;
  --app-bg: #222;
  --content-bg: #111;
  --nav-button-color: var(--app-bg);
  --nav-button-visiting: #444;
}


.App {
  display: flex;
  /* position: fixed; */
  /* height: 100%; */
  min-height: 100vh;
  width: 100%;
  background-color: var(--app-bg);
  justify-content: center;
}

.app-content {
  display: flex;
  /* position: fixed; */
  /* height: 100%; */
  /* min-height: 100vh; */
  width: 100%;
  max-width: var(--app-width);
  background-color: var(--content-bg);
}

.app-content.mobile {
  background-color: var(--app-bg);
}

h1 {
  color:var(--accent-text);
  margin-bottom: -1.5rem;
}

h2 {
  color:cadetblue
}

a:link {
  color: gray;
}

a:visited {
  color: antiquewhite;
}

a:hover {
  color: hotpink;
}

a:active {
  color:aquamarine
}

.youtube {
  width: 100%;
  aspect-ratio: 16 / 9;
}

#sunsFace {
  width: 80%;
  max-width: 400px;
  border-radius: 20px;
}

#email {
  color:var(--accent-text);
  font-weight:700;
}


.header {
  display: flex;
  margin-bottom: 1rem;
}

.header img {
  width: var(--menu-width);
  height: auto;
  cursor: pointer;
  border-radius: var(--menu-button-border);
}

.navigation-menu.desktop {
  position:fixed;
  width: var(--menu-width);
  top: 0;
  /* left: 0; */
  height: 100vh;
  padding: var(--menu-padding);
  background-color: var(--content-bg);
  border-right-style: solid;
  border-right-width: var(--menu-border);
  border-right-color: var(--app-bg);
}

.navigation-menu.mobile {
  background-color: var(--content-bg);
}


.main-section {
  width: 100%;
  max-width: var(--content-width);
  padding: 1rem 2rem;
  /* padding-left: 2rem;
  padding-right: 2rem; */
  font-size: 1rem;
  font-weight: 400;
  color: #BBB;
  overflow-y: auto;
  overflow-x: auto;
}

.main-section.desktop {
  margin-left: var(--total-menu-width);
}
.main-section.mobile {
  max-width: 100vw;
}

button {
  display: block;
  margin-top: 0.3rem;
  width: var(--menu-width);
  border-style: solid;
  border-radius: var(--menu-button-border);
  border-color: #333;
  background-color: var(--nav-button-color);
  font-size: 1rem;
  font-weight: 550;
  text-align: left;
  color:linen;
  cursor: pointer;
}

button:hover {
  text-decoration: underline;
}

button.visiting {
  background-color: var(--nav-button-visiting);
}


.stream {
  padding-inline: 2rem;
  padding-block: 2rem;
}

.left-align {
  text-align: left
}

.event {
  text-align: left;
  margin-bottom: 1rem;
}

.eventInfo {
  font-size: 0.9rem;
}

.date {
  color: var(--accent-text);
  font-weight: 660;
  font-size: 1.1rem;
}

.friendList {
  text-align: left;
  list-style-type: none;
}

.accentText {
  color: var(--accent-text);
}

.bioParagraph {
  margin-bottom: 1rem;
}