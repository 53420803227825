.navigation-menu.mobile {
    display: table;
    padding:1rem;
    position: fixed;
    /* background-color: rgb(25, 29, 28); */
    width: 100%;
    top: 0px;
    left: 0px;
  }
  
  button.mobile {
    display: inline;
    margin-right: 1rem;
    /* height: 4rem; */
    /* color:rgb(203, 215, 214); */
  }

  .hamburger-react {
    display: table-cell;
    float: right;
    margin-right: 2rem;
  }


.headerContent {
    display: table-cell;
    vertical-align: middle;
    color:var(--accent-text);
    padding-left: 1rem;
    font-size:1.8rem;
    font-weight: 600;
  }
  
.headerSubcontent {
font-size:1.5rem;
display: inline-block;
color:cadetblue;
}
